import { useLogger, RecipientAddress } from '@vp/shared-capabilities-component-library/components'
import { Card, ModalDialogBody, ModalDialogFooter, Typography } from '@vp/swan'
import { RecipientAddressFormWrapper } from 'components/recipient-form/recipient-form-wrapper.component'
import { RECIPIENT_ADDRESS_FORM_ID } from 'constants/global.constant'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { ErrorBoundary, UnknownError } from 'lib/errors'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Notifier } from 'utilities/Notifier'
import { AddressDisplayWithFallback } from '../address-display-with-fallback/address-display-with-fallback.component'
import { SaveAndContinueBtn } from './save-continue-btn.component'
import classes from './single-row-edit.module.scss'

export const SingleRowEdit: FC<{
  address: IAddressDetails
  suggestion?: IAddressDetails
  onUpdate: (address: IAddressDetails) => void
  editInProgress: boolean
  isLastItem: boolean
  showError: boolean
}> = ({ address, suggestion, onUpdate, editInProgress, isLastItem, showError }) => {
  const addressFormRef = useRef<HTMLFormElement>(null)
  const workId = useWorkIdQueryParam()
  const notifier = useMemo(() => new Notifier(), [])
  const { logError } = useLogger()
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(editInProgress)

  useEffect(() => {
    if (showError) {
      logError('Error occurred while updating address', { contextData: { workId, id: address.rowIndex } })
    }
  }, [showError, workId, address.rowIndex, logError])

  const recipientAddressFormWrapperProps = {
    address: address,
    onSubmitForm: (validatedAddress: RecipientAddress) => {
      const transformedAddress = {
        ...address,
        ...validatedAddress,
        lastName: validatedAddress.recipient as string,
      }
      onUpdate(transformedAddress)
      return Promise.resolve(true)
    },
    setSubmitButtonDisabled,
  }

  return (
    <ErrorBoundary>
      <ModalDialogBody>
        {suggestion ? (
          <ErrorBoundary>
            <Typography fontSize={'standard'} mb={2} fontWeight="bold">
              <FormattedMessage
                defaultMessage="Use suggested"
                description="Button label to use suggested address when the particular address was uploaded wrongly"
              />
            </Typography>
            <Card bordered backgroundColor="standard" mb={6} role="button" className={classes.suggestion} onClick={() => onUpdate({ ...suggestion })}>
              <AddressDisplayWithFallback address={suggestion} />
            </Card>
          </ErrorBoundary>
        ) : null}
        <RecipientAddressFormWrapper {...recipientAddressFormWrapperProps}></RecipientAddressFormWrapper>
      </ModalDialogBody>
      <ModalDialogFooter pinned>
        <SaveAndContinueBtn
          hasNext={!isLastItem}
          onClick={() => {
            notifier.notify()
            addressFormRef?.current?.submit()
          }}
          disabled={submitButtonDisabled}
          formId={RECIPIENT_ADDRESS_FORM_ID}
        />
        {showError && <UnknownError />}
      </ModalDialogFooter>
    </ErrorBoundary>
  )
}
