import { AddressDisplay } from '@vp/address-display'
import { Box, Typography } from '@vp/swan'
import { useLocalization } from 'hooks/use-localization'
import { ErrorBoundary } from 'lib/errors'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { FC } from 'react'
import classes from './address-display.module.scss'
const AddressDisplayFallback: FC<{ address: IAddressDetails }> = ({ address }) => (
  <Box>
    <Typography fontSize={'small'} textColor="subtle">
      {address.streetNumber} {address.streetName}
    </Typography>
    <Typography fontSize={'small'} textColor="subtle">
      {address.city} {address.state} {address.postalCode}
    </Typography>
    <Typography fontSize={'small'} textColor="subtle">
      {address.country}
    </Typography>
  </Box>
)

const FullName: FC<{ address: IAddressDetails }> = ({ address }) => (
  <Typography fontSize={'standard'} textColor="standard">
    {address.salutation} {address.firstName} {address.middleName} {address.lastName} {address.suffix} {address.title}
  </Typography>
)

export const AddressDisplayWithFallback: FC<{ address: IAddressDetails }> = ({ address }) => {
  const { locale } = useLocalization()
  return (
    <>
      <FullName address={address} />
      <ErrorBoundary fallback={<AddressDisplayFallback address={address} />}>
        <div className={classes.addressContent}>
          <AddressDisplay
            locale={locale}
            address={{
              streetName: address.streetName || '',
              additionalStreetInfo: address.additionalStreetInfo || '',
              postalCode: address.postalCode || '',
              city: address.city || '',
              state: address.state || '',
              country: address.country || '',
              phone: address.phone || '',
              company: address.company || address.companyName || '',
              email: '',
              id: address.rowIndex.toString(),
              firstName: '',
              lastName: '',
            }}
          />
        </div>
      </ErrorBoundary>
    </>
  )
}
