import { AddressErrorDetails, AddressFieldsValidityConfidence } from './address-list.types'

export enum AddressType {
  INVALID = 'INVALID',
  DUPLICATE = 'DUPLICATE',
  VALID = 'VALID',
}

export enum AddressTypeValues {
  INVALID = 'errors',
  DUPLICATE = 'duplicates',
  VALID = 'valids',
}

export enum ActionType {
  NONE = 'NONE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  SELECT = 'SELECT',
  MERGE = 'MERGE',
}

export type MultiSelectionValue = Record<string, boolean>

export enum MultiSelectionStatus {
  ALL = 'ALL',
  NONE = 'NONE',
  SOME = 'SOME',
}

export type MultiSelectionAction =
  | {
      type: 'toggle'
      key: string
    }
  | {
      type: 'reset'
      values: string[]
    }
  | { type: 'selectAll' }
  | { type: 'selectNone' }

export type CommonAddressRowProps<T> = {
  address: T
  errors?: AddressErrorDetails
  isSelected: boolean
  rowId: string
  showDivider?: boolean
  type: AddressType
  isDuplicatedGroupValid?: boolean
  isDuplicatedGroupSamePerson?: boolean
  validityConfidence?: AddressFieldsValidityConfidence
}

export type RowAddressActionParams = {
  isOpen: boolean
  type: AddressType
  ids: Array<string>
  onExit: () => void
}
