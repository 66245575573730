import { Button } from '@vp/swan'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const SaveAndContinueBtn: FC<{
  hasNext: boolean
  onClick: () => void
  disabled: boolean
  formId?: string
}> = ({ hasNext, onClick, disabled, formId }) => (
  <Button type="submit" form={formId} skin="primary" width="full-width" style={{ width: '-webkit-fill-available' }} disabled={disabled} onClick={onClick}>
    {hasNext ? (
      <FormattedMessage
        defaultMessage="Save and Continue"
        description="Button label to save an updated address and continue further to edit other addresses in the list"
      />
    ) : (
      <FormattedMessage defaultMessage="Save" description="Button label to save an updated address" />
    )}
  </Button>
)
