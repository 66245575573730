import { useIntl } from 'react-intl'

export function useMsgAddressFormatAriaLabel() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'See address Format Information',
    description: 'Label to see the address format information',
  })
}
export function useMsgConfirmDeliverability() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Confirm deliverability',
    description: 'Checklist task that is performed by Mailing Services after uploading addresses',
  })
}

export function useMsgStandardize() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Standardize their address format',
    description: 'Checklist task that is performed by Mailing Services after uploading addresses',
  })
}

export function useMsgZip() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Add ZIP+4 where necessary',
    description: 'Checklist task that is performed by Mailing Services after uploading addresses',
  })
}
