import { useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'

export function useMsgUnSelectAllValid() {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  return isPostcard
    ? intl.formatMessage({
        defaultMessage: 'Unselect all valid',
        description: 'Checkbox title to unselect all options e.g. Unselect all errors or Unselect all duplicates',
      })
    : intl.formatMessage({
        defaultMessage: 'Unselect all okay',
        description: 'Checkbox title to unselect all options e.g. Unselect all errors or Unselect all duplicates',
      })
}

export function useMsgUnSelectAllInvalids() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Unselect all errors',
    description: 'Checkbox title to unselect all options e.g. Unselect all errors or Unselect all duplicates',
  })
}

export function useMsgUnSelectAllDuplicates() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Unselect all duplicates',
    description: 'Checkbox title to unselect all options e.g. Unselect all errors or Unselect all duplicates',
  })
}
