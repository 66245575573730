import { Button, Checkbox, Divider, FlexBox, Hidden, Spinner, Typography } from '@vp/swan'
import classNames from 'classnames'
import { withErrorBoundary } from 'lib/errors'
import { useAddressError } from 'lib/intl/msg-address-errors.hook'
import { useMsgAlternateTextDeleteLogo, useMsgAlternateTextEditLogo } from 'lib/intl/msg-alternate-texts.hooks'
import { useMsgDeleteLabel } from 'lib/intl/msg-delete-action.hooks'
import { useMsgEditLabel } from 'lib/intl/msg-edit-action.hooks'
import { useMsgModalLoadingText } from 'lib/intl/msg-modal.hooks'
import { useOnDeleteHook } from 'modules/review/components/modal-delete/on-delete.hook'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { AddressType, CommonAddressRowProps } from 'modules/review/types/address-multi-selection.types'
import { FC } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import deleteLogo from '../../assets/delete.svg'
import editLogo from '../../assets/edit.svg'
import errorIcon from '../../assets/error.svg'
import { AddressDisplayWithFallback } from '../address-display-with-fallback/address-display-with-fallback.component'
import { useAddressActions } from '../address-tab/address-actions.context'
import { useAddressSelection } from '../address-tab/address-selection.context'
import { AddressValidityConfidence } from '../address-validity-confidence/address-validity-confidence.component'
import classes from './address-row.module.scss'

const AddressRowInternal: FC<CommonAddressRowProps<IAddressDetails>> = ({
  address,
  isSelected,
  rowId,
  showDivider,
  errors,
  type,
  isDuplicatedGroupValid,
  isDuplicatedGroupSamePerson,
  validityConfidence,
}) => {
  const deleteLabel = useMsgDeleteLabel()
  const editLabel = useMsgEditLabel()
  const alternateTextDeleteLogo = useMsgAlternateTextDeleteLogo()
  const alternateTextEditLogo = useMsgAlternateTextEditLogo()
  const { onEdit } = useAddressActions()
  const { onToggle } = useAddressSelection()
  const errorMessages = useAddressError(errors)
  const intl = useIntl()
  const loadingMessage = useMsgModalLoadingText()
  const selectAddressMessage = intl.formatMessage({
    defaultMessage: 'Select address',
    description: 'Button label to select one of the duplicate addresses',
  })
  const { onDelete, isLoading, isEnabled } = useOnDeleteHook([rowId], type)
  const editRecipientMessage = intl
    .formatMessage({ defaultMessage: 'Edit recipient', description: 'Title for the recipient address to be edited' })
    .concat(' ', address.firstName as string, ' ', address.lastName)
  const deleteRecipientMessage = intl
    .formatMessage({ defaultMessage: 'Delete recipient', description: 'Title for the recipient address to be deleted' })
    .concat(' ', address.firstName as string, ' ', address.lastName)

  return (
    <>
      <FlexBox paddingY={5} backgroundColor="standard" className={classes.listRow}>
        <label htmlFor={selectAddressMessage}></label>
        <Checkbox mt="2" mr="7" id={selectAddressMessage} checked={isSelected || false} onChange={() => onToggle(rowId)} />
        <FlexBox className={classes.addressContent} flexDirection="column">
          <AddressDisplayWithFallback address={address} />
          {type === AddressType.INVALID && (
            <FlexBox alignItems="center">
              <img src={errorIcon} />
              <Typography textColor="error" fontSize={'small'} ml={2} fontWeight="bold">
                {errorMessages.length === 0 && <AddressValidityConfidence validityConfidence={validityConfidence} />}
                {errorMessages.length === 1 && errorMessages[0]}
                {errorMessages.length > 1 && (
                  <FormattedMessage
                    defaultMessage="Multiple errors in recipient details"
                    description="error message to indicate multiple address field errors"
                  />
                )}
              </Typography>
            </FlexBox>
          )}
          {isSamePersonInvalidDuplicates({ type, isDuplicatedGroupValid, isDuplicatedGroupSamePerson }) && (
            <FlexBox alignItems="center">
              <img src={errorIcon} />
              <Typography textColor="error" fontSize={'small'} ml={2} fontWeight="bold">
                {isDuplicatedGroupSamePerson ? (
                  <>
                    <AddressValidityConfidence validityConfidence={validityConfidence} />
                  </>
                ) : (
                  <>
                    <AddressValidityConfidence validityConfidence={validityConfidence} />
                    <FormattedMessage defaultMessage="You need to correct the address before selecting addresses." />
                  </>
                )}
              </Typography>
            </FlexBox>
          )}

          <Hidden lg xl>
            <Button aria-label={editRecipientMessage} skin="unstyled" style={{ textDecoration: 'underline' }} mr={4} onClick={() => onEdit(rowId)}>
              <FormattedMessage defaultMessage="Edit" description="Button label to edit an address" />
            </Button>
            <Button
              aria-label={deleteRecipientMessage}
              skin="unstyled"
              style={{ textDecoration: 'underline' }}
              onClick={onDelete}
              disabled={!isEnabled || isLoading}
            >
              <FormattedMessage defaultMessage="Delete" description="Button label to delete an address" />
              {isLoading && <Spinner accessibleText={loadingMessage} />}
            </Button>
          </Hidden>
        </FlexBox>
        <Hidden md sm xs className={classes.roundButtons}>
          <Button aria-label={deleteRecipientMessage} buttonShape="round" onClick={onDelete} title={deleteLabel} disabled={!isEnabled || isLoading}>
            <img src={deleteLogo} alt={alternateTextDeleteLogo} className={classNames([isLoading ? classes.fadedLogo : ''])} />
            {isLoading && <Spinner className={classes.deletePreloader} accessibleText={loadingMessage} />}
          </Button>
          <Button aria-label={editRecipientMessage} buttonShape="round" ml={3} onClick={() => onEdit(rowId)} title={editLabel}>
            <img src={editLogo} alt={alternateTextEditLogo} />
          </Button>
        </Hidden>
      </FlexBox>
      {showDivider ? <Divider /> : null}
    </>
  )
}

function isSamePersonInvalidDuplicates(params: {
  type: AddressType
  isDuplicatedGroupValid: boolean | undefined
  isDuplicatedGroupSamePerson: boolean | undefined
}): boolean {
  const { type, isDuplicatedGroupValid, isDuplicatedGroupSamePerson } = params
  return type === AddressType.DUPLICATE && isDuplicatedGroupValid !== undefined && isDuplicatedGroupSamePerson !== undefined && !isDuplicatedGroupValid
}

export const AddressRow = withErrorBoundary(AddressRowInternal)
