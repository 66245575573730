import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { Button, Spinner } from '@vp/swan'
import { ExceededAddressesModal } from 'components/exceeded-addresses-modal/exceeded-addresses-modal'
import { useRequestIdQueryParam, useWorkIdQueryParam } from 'contexts/query-param.context'
import { useNavigateToConfirmation } from 'hooks/navigate.hooks'
import { useAddressListQuery, useMailingListCreate } from 'lib/address-list'
import { useValidateMailingListPostcardMutation } from 'lib/address-list/address-book.hooks'
import { withErrorBoundary } from 'lib/errors'
import { useMsgErrorWhileFetching, useMsgInvalidAddressList } from 'lib/intl/msg-errors.hooks'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useProductInfoUpdate } from 'lib/product-info/product-info.context'
import { PageName } from 'lib/telemetry'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useErrorToastWithDuration } from 'lib/toast/toast.hooks'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { usePromoteDuplicate } from './use-promote-duplicate.hook'

const BtnMailingListCreateInternal: FC<{ mailingListName?: string | null; enabled: boolean }> = ({ mailingListName = null, enabled }) => {
  const {
    isEnabled: isListCreationEnabled,
    mutationResult: { mutateAsync: create, isLoading, isSuccess },
  } = useMailingListCreate()
  const unknownErrorToast = useToastSomethingWentWrong()
  const updateSelection = useProductInfoUpdate()
  const isNextEnabled = isListCreationEnabled && enabled && mailingListName && !isLoading && !isSuccess
  const [isUpdatingWork, setIsUpdatingWork] = useState(false)
  const navigateToConfirmation = useNavigateToConfirmation(true, false)
  const workId = useWorkIdQueryParam()
  const errorMessage = useMsgInvalidAddressList() as string
  const errorToast = useErrorToastWithDuration(errorMessage)
  const invalidRequestIdMessage = useMsgErrorWhileFetching()
  const invalidRequestIdToast = useErrorToastWithDuration(invalidRequestIdMessage)
  const requestId = useRequestIdQueryParam() || undefined
  const { refetch } = useAddressListQuery({ requestId, retryCount: 0 })
  const [mailingListId, setMailingListId] = useState<string>()
  const { formatMessage } = useIntl()

  const { logError } = useLogger()

  useEffect(() => {
    if (mailingListId) {
      navigateToConfirmation(mailingListId)
    }
  }, [mailingListId, navigateToConfirmation])

  const {
    mutationResult: {
      /* TODO: This should be enable if task https://vistaprint.atlassian.net/browse/AMS-399 is implemented. -> mutateAsync: validateMailingListPostcard, */
      isLoading: isValidatingInProgressList,
      data: validatedInProgressListResult,
    },
  } = useValidateMailingListPostcardMutation()
  const createMailingList = async () => {
    if (!requestId) return null
    const newAddresses = await refetch()
    if (newAddresses.isError) {
      invalidRequestIdToast()
      return
    }

    if (newAddresses.data?.status === 'invalidData' || (newAddresses.data?.invalidAddresses && newAddresses.data.invalidAddresses.length > 0)) {
      errorToast()
      return
    }
    const data = await create({ mailingListName })
    setIsUpdatingWork(true)
    updateSelection({ qty: data.addressIds.length })
    newRelicAddPageAction('Create mailing list', { result: 'success' })
    setMailingListId(data.mailingListId)
  }

  const { isPromoteDuplicateInProgress, promoteDuplicates } = usePromoteDuplicate(createMailingList)

  const confirmGoToNext = async () => {
    try {
      if (isNextEnabled) {
        promoteDuplicates()
      }
    } catch {
      unknownErrorToast()
      newRelicAddPageAction('Create mailing list', { result: 'error' })

      logError('Error occurred while creating mailing list', { contextData: { mailingListName, workId } })
    }
  }

  const next = async () => {
    // TODO: This should be enable if task https://vistaprint.atlassian.net/browse/AMS-399 is implemented.
    // const result = await validateMailingListPostcard({ requestId })
    // if (result.isCompatible) {
    //   await confirmGoToNext()
    // }
    await confirmGoToNext()
  }

  return (
    <>
      {validatedInProgressListResult && !validatedInProgressListResult.isCompatible && (
        <ExceededAddressesModal
          showDownloadMailingListButton={false}
          showEditRecipientsButton={true}
          invalidRecipients={validatedInProgressListResult?.numberOfIncompatibleAddresses ?? 0}
          totalRecipients={validatedInProgressListResult?.totalAddresses ?? 0}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          pageName={PageName.REVIEW_PAGE}
          continueAction={confirmGoToNext}
        />
      )}
      <Button disabled={!isNextEnabled || isValidatingInProgressList} skin="primary" onClick={next}>
        <FormattedMessage defaultMessage="Next" description="Button label for next page navigation" />
        {(isLoading || isUpdatingWork || isPromoteDuplicateInProgress) && (
          <Spinner
            ml={3}
            mr={0}
            accessibleText={formatMessage({
              defaultMessage: 'Loading...',
            })}
          />
        )}
      </Button>
    </>
  )
}

export const BtnMailingListCreate = withErrorBoundary(BtnMailingListCreateInternal)
