import { Button, Checkbox, FlexBox, Hidden, Spinner, Typography } from '@vp/swan'
import classNames from 'classnames'
import { useMsgAlternateTextDeleteLogo, useMsgAlternateTextEditLogo } from 'lib/intl/msg-alternate-texts.hooks'
import { useMsgModalLoadingText } from 'lib/intl/msg-modal.hooks'
import { useAddressTabHeaderMessage } from 'modules/review/components/address-tab/address-tab-header.hook'
import { useOnDeleteHook } from 'modules/review/components/modal-delete/on-delete.hook'
import { AddressType, MultiSelectionStatus } from 'modules/review/types/address-multi-selection.types'
import { FC, useEffect, useRef } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import deleteLogo from '../../assets/delete.svg'
import editLogo from '../../assets/edit.svg'
import { useAddressActions } from './address-actions.context'
import { useAddressSelection } from './address-selection.context'
import headerClasses from './address-tab-header.module.scss'
import classes from './address-tab.module.scss'

const SelectionCheckbox: FC<{ message: string }> = ({ message }) => {
  const checkboxRef = useRef<HTMLInputElement>(null)
  const { toggleStatus, status } = useAddressSelection()

  useEffect(() => {
    if (!checkboxRef.current) return undefined
    if (status === MultiSelectionStatus.SOME) {
      checkboxRef.current.indeterminate = true
      checkboxRef.current.checked = false
    } else {
      checkboxRef.current.indeterminate = false
      checkboxRef.current.checked = status === MultiSelectionStatus.ALL
    }
  }, [status])

  return (
    <>
      <label htmlFor={message}></label>
      <Checkbox id={message} ref={checkboxRef} onClick={toggleStatus} mr={7} my={3} title={message} />
    </>
  )
}

export const AddressTabHeader: FC<{ addressType: AddressType }> = ({ addressType }) => {
  const { selectedKeys } = useAddressSelection()
  const { onEdit } = useAddressActions()
  const { onDelete, isLoading, isEnabled } = useOnDeleteHook(selectedKeys, addressType)
  const messages = useAddressTabHeaderMessage(addressType, selectedKeys.length)
  const alternateTextDeleteLogo = useMsgAlternateTextDeleteLogo()
  const alternateTextEditLogo = useMsgAlternateTextEditLogo()
  const loadingMessage = useMsgModalLoadingText()

  return (
    <FlexBox justifyContent="space-between" alignItems="center" paddingY={4} px="7" mb="5" className={classes.headerShadow}>
      <FlexBox alignItems="center">
        <SelectionCheckbox message={selectedKeys.length ? messages.checkboxTitleUnSelectAll : messages.checkboxTitleSelectAll} />
        <Typography fontSize={'standard'} as="span">
          {selectedKeys.length ? messages.addressSelection : <FormattedMessage defaultMessage="Select all" description="Label for selecting all addresses" />}
        </Typography>
      </FlexBox>
      {selectedKeys.length ? (
        <>
          <Hidden md sm xs>
            <Button size="mini" disabled={!selectedKeys.length || !isEnabled || isLoading} onClick={onDelete} title={messages.deleteActionButtonTitle}>
              <img src={deleteLogo} alt={alternateTextDeleteLogo} className={classNames([isLoading ? headerClasses.fadedLogo : ''])} />
              <Typography fontSize={'small'} ml={2} className={classNames([isLoading ? headerClasses.fadedLogo : ''])}>
                (
                <FormattedNumber value={selectedKeys.length} />)
              </Typography>
              {isLoading && <Spinner className={headerClasses.deletePreloader} accessibleText={loadingMessage} />}
            </Button>
            <Button size="mini" disabled={!selectedKeys.length} onClick={() => onEdit(selectedKeys)} ml={3} title={messages.editActionButtonTitle}>
              <img src={editLogo} alt={alternateTextEditLogo} />
              <Typography fontSize={'small'} ml={2}>
                (<FormattedNumber value={selectedKeys.length} />)
              </Typography>
            </Button>
          </Hidden>
          <Hidden lg xl>
            <Button skin="unstyled" style={{ textDecoration: 'underline' }} mr={4} onClick={() => onEdit(selectedKeys)}>
              <FormattedMessage defaultMessage="Edit" description="Button label to edit an address" />
            </Button>
            <Button skin="unstyled" style={{ textDecoration: 'underline' }} onClick={onDelete} disabled={!isEnabled || isLoading}>
              <FormattedMessage defaultMessage="Delete" description="Button label to delete an address" />
              {isLoading && <Spinner accessibleText={loadingMessage} />}
            </Button>
          </Hidden>
        </>
      ) : null}
    </FlexBox>
  )
}
