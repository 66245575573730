import { AddressErrorDetails, errorCode } from 'modules/review/types/address-list.types'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

function useMsgRecipientError(code?: errorCode) {
  const intl = useIntl()
  switch (code) {
    case 'invalidCharacters':
    case 'invalidFormat':
    case 'invalidValue':
      return intl.formatMessage({ defaultMessage: 'The recipient is invalid' })
    case 'missingField':
      return intl.formatMessage({ defaultMessage: 'The recipient is missing' })
    case 'tooLong':
      return intl.formatMessage({ defaultMessage: 'The recipient is too long' })
    case 'tooShort':
      return intl.formatMessage({ defaultMessage: 'The recipient is too short' })
    default:
      return null
  }
}

function useMsgStreetNameError(code?: errorCode) {
  const intl = useIntl()
  switch (code) {
    case 'invalidCharacters':
    case 'invalidFormat':
    case 'invalidValue':
      return intl.formatMessage({ defaultMessage: 'Enter a valid street name' })
    case 'missingField':
      return intl.formatMessage({ defaultMessage: 'Enter the street name' })
    case 'tooLong':
      return intl.formatMessage({ defaultMessage: 'Street Name too long' })
    case 'tooShort':
      return intl.formatMessage({ defaultMessage: 'Street Name too short' })
    default:
      return null
  }
}

function useMsgAdditionalStreetInfoError(code?: errorCode) {
  const intl = useIntl()
  switch (code) {
    case 'invalidCharacters':
    case 'invalidFormat':
    case 'invalidValue':
      return intl.formatMessage({ defaultMessage: 'Enter a valid additional street info' })
    case 'missingField':
      return intl.formatMessage({ defaultMessage: 'Enter the additional street info' })
    case 'tooLong':
      return intl.formatMessage({ defaultMessage: 'Additional Street Info too long' })
    case 'tooShort':
      return intl.formatMessage({ defaultMessage: 'Additional Street Info too short' })
    default:
      return null
  }
}

function useMsgPostalCodeError(code?: errorCode) {
  const intl = useIntl()
  switch (code) {
    case 'invalidCharacters':
    case 'invalidFormat':
    case 'invalidValue':
      return intl.formatMessage({ defaultMessage: 'Enter a valid zip code' })
    case 'missingField':
      return intl.formatMessage({ defaultMessage: 'Enter the zip code required' })
    case 'tooLong':
      return intl.formatMessage({ defaultMessage: 'Zip Code too long' })
    case 'tooShort':
      return intl.formatMessage({ defaultMessage: 'Zip Code too short' })
    default:
      return null
  }
}

function useMsgCityError(code?: errorCode) {
  const intl = useIntl()
  switch (code) {
    case 'invalidCharacters':
    case 'invalidFormat':
    case 'invalidValue':
      return intl.formatMessage({ defaultMessage: 'Enter a valid city name' })
    case 'missingField':
      return intl.formatMessage({ defaultMessage: 'Enter the city name' })
    case 'tooLong':
      return intl.formatMessage({ defaultMessage: 'City Name too long' })
    case 'tooShort':
      return intl.formatMessage({ defaultMessage: 'City Name too short' })
    default:
      return null
  }
}

function useMsgStateError(code?: errorCode) {
  const intl = useIntl()
  switch (code) {
    case 'invalidCharacters':
    case 'invalidFormat':
    case 'invalidValue':
      return intl.formatMessage({ defaultMessage: 'Enter a valid state name' })
    case 'missingField':
      return intl.formatMessage({ defaultMessage: 'Enter the state name' })
    case 'tooLong':
      return intl.formatMessage({ defaultMessage: 'State Name too long' })
    case 'tooShort':
      return intl.formatMessage({ defaultMessage: 'State Name too short' })
    default:
      return null
  }
}

function useMsgCompanyError(code?: errorCode) {
  const intl = useIntl()
  switch (code) {
    case 'invalidCharacters':
      return intl.formatMessage({ defaultMessage: 'Enter a valid company name (invalid characters)' })
    case 'tooLong':
      return intl.formatMessage({ defaultMessage: 'Company name too long' })
    case 'tooShort':
      return intl.formatMessage({ defaultMessage: 'Company name too short' })
    default:
      return null
  }
}

export function useAddressError(errorObj?: AddressErrorDetails) {
  const recipientErrorMsg = useMsgRecipientError(errorObj?.recipient?.errorId)
  const streetNameErrorMsg = useMsgStreetNameError(errorObj?.streetName?.errorId)
  const additionalStreetInfoErrorMsg = useMsgAdditionalStreetInfoError(errorObj?.additionalStreetInfo?.errorId)
  const postalCodeErrorMsg = useMsgPostalCodeError(errorObj?.postalCode?.errorId)
  const cityErrorMsg = useMsgCityError(errorObj?.city?.errorId)
  const stateErrorMsg = useMsgStateError(errorObj?.state?.errorId)
  const companyErrorMsg = useMsgCompanyError(errorObj?.company?.errorId)

  return useMemo(() => {
    const allErrors = []

    if (recipientErrorMsg) {
      allErrors.push(recipientErrorMsg)
    }
    if (streetNameErrorMsg) {
      allErrors.push(streetNameErrorMsg)
    }
    if (additionalStreetInfoErrorMsg) {
      allErrors.push(additionalStreetInfoErrorMsg)
    }
    if (postalCodeErrorMsg) {
      allErrors.push(postalCodeErrorMsg)
    }
    if (cityErrorMsg) {
      allErrors.push(cityErrorMsg)
    }
    if (stateErrorMsg) {
      allErrors.push(stateErrorMsg)
    }
    if (companyErrorMsg) {
      allErrors.push(companyErrorMsg)
    }

    return allErrors
  }, [postalCodeErrorMsg, recipientErrorMsg, streetNameErrorMsg, additionalStreetInfoErrorMsg, cityErrorMsg, stateErrorMsg, companyErrorMsg])
}
