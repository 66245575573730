import { useLogger } from '@vp/shared-capabilities-component-library/components'
import {
  Box,
  Button,
  Checkbox,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
  Spinner,
  Typography,
} from '@vp/swan'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { Error, withErrorBoundary } from 'lib/errors'
import { useMsgDeleteAriaLabel } from 'lib/intl/msg-delete-action.hooks'
import { useMsgErrorWhileDeleting } from 'lib/intl/msg-errors.hooks'
import { useMsgModalClose, useMsgModalLoadingText } from 'lib/intl/msg-modal.hooks'
import { useOnDeleteHook } from 'modules/review/components/modal-delete/on-delete.hook'
import { useReviewDeleteConfirmationConfig } from 'modules/review/contexts/review-config.context'
import { RowAddressActionParams } from 'modules/review/types/address-multi-selection.types'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

const DeleteAddressesInternal: FC<RowAddressActionParams> = ({ type, ids, onExit, isOpen }) => {
  const loadingMessage = useMsgModalLoadingText()
  const deleteAriaLabel = useMsgDeleteAriaLabel()
  const errorWhileDeleting = useMsgErrorWhileDeleting()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useReviewDeleteConfirmationConfig()

  const { onDelete, isEnabled, isError, isLoading } = useOnDeleteHook(
    ids,
    type,
    () => {
      if (dontAskForDeleteConfirmation) {
        setShowDeleteConfirmation(!dontAskForDeleteConfirmation)
      }
      onExit()
    },
    false,
  )

  const [dontAskForDeleteConfirmation, setDontAskForDeleteConfirmation] = useState<boolean>(false)
  const modalCloseLabel = useMsgModalClose()
  const workId = useWorkIdQueryParam()
  const { logError } = useLogger()

  useEffect(() => {
    if (isError) {
      logError(errorWhileDeleting, { contextData: { workId, type } })
    }
  }, [isError, errorWhileDeleting, workId, type, logError])

  return (
    <div>
      {ids.length && showDeleteConfirmation && (
        <ModalDialog isOpen={isOpen} onRequestDismiss={onExit}>
          <ModalDialogContent aria-labelledby={deleteAriaLabel}>
            <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} onClick={onExit} />
            <ModalDialogHeader>
              <ModalDialogTitle>
                {ids.length === 1 ? (
                  <FormattedMessage defaultMessage="Are you sure you want to delete 1 recipient?" description="Are you sure you want to delete 1 recipient?" />
                ) : (
                  <FormattedMessage
                    defaultMessage="Are you sure you want to delete {totalRecipientsToDelete} recipients?"
                    description="Are you sure you want to delete 3 recipients?"
                    values={{ totalRecipientsToDelete: ids.length }}
                  />
                )}
              </ModalDialogTitle>
            </ModalDialogHeader>
            <ModalDialogBody>
              <Typography fontSize={'standard'}>
                <FormattedMessage defaultMessage="Deleting a recipient will permanently remove them from the list." />
              </Typography>
              <Typography fontSize={'small'} fontWeight="bold" mt={5}>
                <label htmlFor="checked">
                  <Checkbox checked={dontAskForDeleteConfirmation} id="checked" onChange={() => setDontAskForDeleteConfirmation(prev => !prev)} mr={3} />
                  <FormattedMessage defaultMessage="Don't ask me again" description="Option for not showing the confirm message again" />
                </label>
              </Typography>
            </ModalDialogBody>
            <Box>
              <ModalDialogButtons>
                <Button size="mini" skin="secondary" onClick={onExit}>
                  <FormattedMessage defaultMessage="Cancel" description="Button label for cancelling a process" />
                </Button>
                <Button size="mini" skin="primary" onClick={onDelete} disabled={isLoading || !isEnabled}>
                  <FormattedMessage defaultMessage="Delete" description="Button label to delete an address" />
                  {isLoading && <Spinner ml={4} mr={0} accessibleText={loadingMessage} />}
                </Button>
              </ModalDialogButtons>
            </Box>
            {isError && <Error message={errorWhileDeleting} textAlign="left" />}
          </ModalDialogContent>
        </ModalDialog>
      )}
    </div>
  )
}

export const DeleteAddresses = withErrorBoundary(DeleteAddressesInternal)
