import { Box, Checkbox, Divider, FlexBox, TabContent, TabHeader, Tabs, TabsContents, TabsHeaders, Typography } from '@vp/swan'
import classNames from 'classnames'
import { ShimmerTablet } from 'components/loader/shimmer/shimmer.component'
import { useAddressList } from 'lib/address-list'
import { TabIds, useReviewStrategyConfig, useReviewTabId } from 'modules/review/contexts/review-config.context'
import { FC, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import { DuplicateAddressTab } from '../address-tab/duplicate-tab.component'
import { InvalidAddressTab } from '../address-tab/invalid-tab.component'
import { ValidAddressTab } from '../address-tab/valid-tab.component'
import classes from './address-tabs.module.scss'

const tabBorderColor: Record<NonNullable<TabIds>, string> = {
  valid: classes.validColor,
  invalid: classes.invalidColor,
  duplicate: classes.duplicateColor,
}

type AvailableTab = {
  valid: boolean
  invalid: boolean
  duplicate: boolean
}

export const AddressTabsPlaceholder: FC<unknown> = () => (
  <Box className={classNames(classes.tabsContainer, classes.validColor)} my={6} backgroundColor={'standard'}>
    <Box pt={4}>
      <ShimmerTablet width={25} size="large" />
    </Box>
    <Divider mb={5} />
    {[1, 2, 3].map(u => (
      <Box p={4} key={u}>
        <FlexBox>
          <Checkbox ml={5} mr={7} />
          <Box className="flex-1-1">
            <ShimmerTablet size="small" mb={3} />
            <ShimmerTablet size="small" mb={3} width={25} />
            <ShimmerTablet size="small" mb={3} width={75} />
            <ShimmerTablet size="small" width={30} />
          </Box>
        </FlexBox>
        <Divider mt={6} />
      </Box>
    ))}
  </Box>
)

function getActiveTab(validTab: AvailableTab, currentTab: TabIds): TabIds {
  if (currentTab && validTab[currentTab]) return currentTab
  if (validTab.invalid) return 'invalid'
  if (validTab.duplicate) return 'duplicate'
  if (validTab.valid) return 'valid'
  return null
}

export const AddressTabs: FC<{ isIdle: boolean; isLoading: boolean }> = ({ isIdle, isLoading }) => {
  const { data: addresses } = useAddressList()
  const [activeTabId, setActiveTabId] = useReviewTabId()
  const [currentStrategy] = useReviewStrategyConfig()
  const validTabIds = useMemo(
    (): Record<NonNullable<TabIds>, boolean> => ({
      invalid: !!addresses?.invalidAddresses?.length,
      valid: !!addresses?.validAddresses?.length,
      duplicate: !!addresses?.duplicatedAddressGroups?.length,
    }),
    [addresses],
  )
  const isPostcard = usePostcardPRDCheck()

  const currentActiveTab = getActiveTab(validTabIds, activeTabId)

  useEffect(() => {
    setActiveTabId(currentActiveTab)
  }, [currentActiveTab, setActiveTabId])

  if (isIdle || (isLoading && !addresses)) return <AddressTabsPlaceholder />

  if (!addresses) return null

  if (!currentStrategy && (validTabIds.invalid || validTabIds.duplicate)) return null

  if (!currentActiveTab) {
    return (
      <Typography>
        <FormattedMessage defaultMessage="Your address list is empty." />
      </Typography>
    )
  }

  return (
    <Box className={classNames(tabBorderColor[currentActiveTab], classes.tabsContainer)} backgroundColor="standard" pt="5" pb="4" mb="12">
      <Tabs selectedTabId={currentActiveTab} defaultSelectedTabId="invalid" onRequestTabChange={nextActiveTabId => setActiveTabId(nextActiveTabId as TabIds)}>
        <TabsHeaders>
          {validTabIds.invalid && (
            <TabHeader tabId="invalid" paddingX={4}>
              {addresses.invalidAddresses?.length === 1 ? (
                <FormattedMessage defaultMessage="Error (1)" description="Tab label to show 1 invalid address e.g. Errors(1)" />
              ) : (
                <FormattedMessage
                  defaultMessage="Errors ({errorsCount})"
                  description="Tab label to show total number of invalid addresses e.g. Errors(10)"
                  values={{ errorsCount: addresses.invalidAddresses?.length || 0 }}
                />
              )}
            </TabHeader>
          )}
          {validTabIds.duplicate && (
            <TabHeader tabId="duplicate" paddingX={4}>
              {addresses.duplicatedAddressGroups?.reduce((count, group) => count + group.addresses.length, 0) === 1 ? (
                <FormattedMessage defaultMessage="Duplicate (1)" description="Tab label to show 1 duplicate address e.g. Duplicate(1)" />
              ) : (
                <FormattedMessage
                  defaultMessage="Duplicates ({duplicateCount})"
                  description="Tab label to show total number of duplicate addresses e.g. Duplicates(6)"
                  values={{
                    duplicateCount: addresses.duplicatedAddressGroups?.reduce((count, group) => count + group.addresses.length, 0) || 0,
                  }}
                />
              )}
            </TabHeader>
          )}
          {validTabIds.valid && (
            <TabHeader tabId="valid" paddingX={4}>
              {isPostcard ? (
                <FormattedMessage
                  defaultMessage="Valid ({validCount})"
                  description="Tab label to show total number of valid addresses e.g. Valid(6)"
                  values={{ validCount: addresses.validAddresses?.length || 0 }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Okay ({validCount})"
                  description="Tab label to show total number of valid addresses e.g. Valid(6)"
                  values={{ validCount: addresses.validAddresses?.length || 0 }}
                />
              )}
            </TabHeader>
          )}
        </TabsHeaders>
        <TabsContents p="0">
          {!!addresses?.invalidAddresses?.length && (
            <TabContent tabId="invalid">
              <InvalidAddressTab addresses={addresses.invalidAddresses} />
            </TabContent>
          )}
          {!!addresses?.duplicatedAddressGroups?.length && (
            <TabContent tabId="duplicate">
              <DuplicateAddressTab addresses={addresses.duplicatedAddressGroups} />
            </TabContent>
          )}
          {!!addresses?.validAddresses?.length && (
            <TabContent tabId="valid">
              <ValidAddressTab addresses={addresses.validAddresses} />
            </TabContent>
          )}
        </TabsContents>
      </Tabs>
    </Box>
  )
}
