import { Column, GridContainer, Row } from '@vp/swan'
import { FullPageLoader } from 'components/loader/loader.component'
import { PageSeoDescription, PageSeoTitle } from 'components/seo/page-seo.component'
import { useByPassCheck } from 'contexts/query-param.context'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { PageLayout } from 'layouts/page/page.layout'
import { useAddressList } from 'lib/address-list'
import { useMsgSeoDescriptionReviewPage, useMsgSeoTitleReviewPage } from 'lib/intl/msg-seo-info.hooks'
import { PageName } from 'lib/telemetry'
import { PageNameForLogging } from 'lib/telemetry/tracking.types'
import { useWorkDetails } from 'lib/work-entity'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { AddressFormatInfo } from '../components/address-format-info/address-format-info.component'
import { AddressTabs } from '../components/address-tabs/address-tabs.component'
import { BtnMailingListCreate } from '../components/btn-mailing-list-create/btn-mailing-list-create.component'
import { Strategize } from '../components/strategize/strategize.component'
import { ValidationHeader } from '../components/validation-header/validation-header.component'

export const ReviewPage: FC<unknown> = () => {
  const seoTitleReviewPage = useMsgSeoTitleReviewPage()
  const seoDescriptionReviewPage = useMsgSeoDescriptionReviewPage()
  const { data: addresses, isIdle, isLoading, isError, isSuccess } = useAddressList()
  const { data: workDetails } = useWorkDetails()

  const [showSavedStatus, setSavedStatus] = useState(false)
  const tabElem = useRef<HTMLInputElement>(null)
  const validAddressesCount = useMemo(() => addresses?.validAddresses?.length || 0, [addresses?.validAddresses])
  const inValidAddressesCount = useMemo(() => addresses?.invalidAddresses?.length || 0, [addresses?.invalidAddresses])
  const duplicateAddressesCount = useMemo(
    () => addresses?.duplicatedAddressGroups?.reduce((count, group) => count + group.addresses.length, 0) || 0,
    [addresses?.duplicatedAddressGroups],
  )
  const totalAddressCount = useMemo(
    () => validAddressesCount + inValidAddressesCount + duplicateAddressesCount,
    [duplicateAddressesCount, inValidAddressesCount, validAddressesCount],
  )
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)
  const byPassCheck = useByPassCheck()

  const autoMove = () => {
    tabElem?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }
  useEffect(() => {
    setSavedStatus(true)
    const timer = setTimeout(() => {
      setSavedStatus(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [addresses])
  const isPageLoading = isLoading || !workDetails?.workId
  return (
    <>
      <PageSeoTitle content={seoTitleReviewPage} />
      <PageSeoDescription content={seoDescriptionReviewPage} />
      {isPageLoading && !byPassCheck && <FullPageLoader />}
      <PageLayout
        nextElem={<BtnMailingListCreate enabled={!!totalAddressCount && !inValidAddressesCount} mailingListName={addresses?.originalFileName} />}
        fileName={addresses?.originalFileName}
        pageName={trackingProductPageName}
        pageNameForLogging={PageNameForLogging.REVIEW_PAGE}
        onBackConfirm
        showSavedStatus={showSavedStatus}
      >
        <GridContainer pt={{ xs: 8, sm: 9, md: 10 }}>
          <Row sticky>
            <Column spanXs={12} spanSm={12} spanMd={6} spanLg={6} span={6}>
              <ValidationHeader
                valid={addresses?.validAddresses?.length || 0}
                duplicate={duplicateAddressesCount || 0}
                invalid={inValidAddressesCount || 0}
                isError={isError}
                isLoading={isLoading}
                isIdle={isIdle}
                isSuccess={isSuccess}
              />
              <Strategize onChange={autoMove} areAllValid={!inValidAddressesCount && !duplicateAddressesCount} isIdle={isIdle} />
              <div ref={tabElem}>
                <AddressFormatInfo />
              </div>
              <AddressTabs isIdle={isIdle} isLoading={isLoading} />
            </Column>
            <Column sticky span={5} spanMd={6} offset={1} offsetMd={0} style={{ top: '100px' }}></Column>
          </Row>
        </GridContainer>
      </PageLayout>
    </>
  )
}
