import { QUERY_KEY_STRATEGY } from 'constants/query-key.constants'
import { useQueryStringState } from 'hooks/use-query-string-state.hook'
import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from 'react'
import { noOp } from 'utilities/functions.utils'

export type TabIds = 'valid' | 'invalid' | 'duplicate' | null | undefined

type ReactSetState<T> = Dispatch<SetStateAction<T>>

const ReviewConfigDeleteConfirmation = createContext<[boolean, ReactSetState<boolean>]>([true, noOp])
const ReviewConfigTabId = createContext<[TabIds, ReactSetState<TabIds>]>([undefined, noOp])
const ReviewConfigStrategy = createContext<ReturnType<typeof useQueryStringState>>(['', noOp])

export const ReviewConfigContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const deleteConfirmation = useState(true)
  const tabIdConfig = useState<TabIds>()
  const strategyConfig = useQueryStringState(QUERY_KEY_STRATEGY, '')

  return (
    <ReviewConfigDeleteConfirmation.Provider value={deleteConfirmation}>
      <ReviewConfigTabId.Provider value={tabIdConfig}>
        <ReviewConfigStrategy.Provider value={strategyConfig}>{children}</ReviewConfigStrategy.Provider>
      </ReviewConfigTabId.Provider>
    </ReviewConfigDeleteConfirmation.Provider>
  )
}

export function useReviewDeleteConfirmationConfig() {
  return useContext(ReviewConfigDeleteConfirmation)
}

export function useReviewTabId() {
  return useContext(ReviewConfigTabId)
}

export function useReviewStrategyConfig() {
  return useContext(ReviewConfigStrategy)
}
