import { AddressFieldsValidityConfidence, FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE } from 'modules/review/types/address-list.types'
import { FC } from 'react'
import { IntlShape, useIntl } from 'react-intl'

type addressFields = keyof AddressFieldsValidityConfidence
type Translations = {
  [PropertyKey in keyof AddressFieldsValidityConfidence]: string
}

export const AddressValidityConfidence: FC<{ validityConfidence: AddressFieldsValidityConfidence | undefined }> = ({ validityConfidence }) => {
  const intl = useIntl()

  let mainMessage = intl.formatMessage({
    defaultMessage: 'A reliable match has not been found. Please review the address for potential errors.',
  })

  if (!validityConfidence) return <p>{mainMessage}</p>

  mainMessage = intl.formatMessage({
    defaultMessage: 'This address could not be verified. Please review the following fields:',
  })

  const validationFieldsMessage = buildValidationFieldsMessage({ validityConfidence, intl })
  const errorMessage = `${mainMessage} ${validationFieldsMessage}`
  return <p>{errorMessage}</p>
}

const buildValidationFieldsMessage = (params: { validityConfidence: AddressFieldsValidityConfidence; intl: IntlShape }): string | undefined => {
  const { validityConfidence, intl } = params

  const fields = Object.keys(validityConfidence) as addressFields[]
  const unReliableFields: string[] = []

  const translations = buildTranslations(intl)

  fields.forEach(fieldName => {
    if (isFieldNotReliable(validityConfidence[fieldName]) || isFieldQuestionable(validityConfidence[fieldName])) {
      unReliableFields.push(translations[fieldName])
    }
  })

  return buildJoinedFields({ unreliableFields: unReliableFields, intl })
}

const isFieldNotReliable = (fieldValidityConfidence: FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE | undefined): boolean => {
  return fieldValidityConfidence === FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE.notReliable
}

const isFieldQuestionable = (fieldValidityConfidence: FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE | undefined): boolean => {
  return fieldValidityConfidence === FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE.questionable
}

const buildTranslations = (intl: IntlShape): Required<Translations> => {
  // You may wonder why we do this field by field.
  // Intl library analyzes the code by checking all calls to formatMessage
  // those calls need constant string variables, you cannot use variable based messages.
  return {
    state: intl.formatMessage({ defaultMessage: 'State' }),
    postalCode: intl.formatMessage({ defaultMessage: 'Postal code' }),
    city: intl.formatMessage({ defaultMessage: 'City' }),
    postBox: intl.formatMessage({ defaultMessage: 'Postal box' }),
    street: intl.formatMessage({ defaultMessage: 'Street' }),
    streetNumber: intl.formatMessage({ defaultMessage: 'Street number' }),
    subBuilding: intl.formatMessage({ defaultMessage: 'Building unit' }),
  }
}

const buildJoinedFields = (params: { unreliableFields: string[]; intl: IntlShape }): string | undefined => {
  const { unreliableFields, intl } = params

  if (isEmpty(unreliableFields)) {
    return undefined
  }

  return joinWithCommaAnd(unreliableFields, intl.formatMessage({ defaultMessage: 'and' }))
}

const isEmpty = (source: string[]): boolean => {
  return source.length === 0
}

const joinWithCommaAnd = (array: string[], andWord: string): string => {
  if (array.length === 0) {
    return ''
  }

  const doubleQuotedArray = array.map(item => `"${item}"`)

  if (doubleQuotedArray.length === 1) {
    return doubleQuotedArray[0]
  }

  const lastItem = doubleQuotedArray.pop()
  const joinedItems = doubleQuotedArray.join(', ')

  return `${joinedItems} ${andWord} ${lastItem}`
}
