export enum SelectionStrategy {
  NONE = 'NONE',
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export type IAddressDetails = {
  additionalStreetInfo?: string
  addressLine1?: string
  addressLine2?: string
  city: string
  company?: string
  companyName?: string
  country: string
  firstName?: string
  lastName: string
  middleName?: string
  phone?: string
  poBox?: string
  postalCode: string
  region?: string
  rowIndex: number
  salutation?: string
  state: string
  streetName: string
  streetNumber?: string
  suffix?: string
  title?: string
  zipCode?: number
}
export type IAddress = {
  addressId: string
  displayName: string
  personId: string
  structuredAddress: IAddressDetails
}

export type errorCode = 'invalidCharacters' | 'invalidFormat' | 'invalidValue' | 'missingField' | 'tooLong' | 'tooShort'

export type AddressErrorDetails = Record<string, Record<string, errorCode>>

export type DuplicatedAddressGroup = {
  addresses: IAddressDetails[]
  isValid: boolean
  samePerson: boolean
  suggestedAddress?: IAddressDetails
  validityConfidence?: AddressFieldsValidityConfidence
}

export type AddressWithSuggestion = {
  originalAddress: IAddressDetails
  suggestedAddress?: IAddressDetails
  errors?: AddressErrorDetails
  validityConfidence?: AddressFieldsValidityConfidence
}

export type AddressFieldsValidityConfidence = {
  state?: FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE
  postalCode?: FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE
  city?: FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE
  street?: FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE
  streetNumber?: FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE
  postBox?: FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE
  subBuilding?: FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE
}

export enum FOUND_ADDRESS_FIELD_VALIDITY_CONFIDENCE {
  reliable = 'reliable',
  questionable = 'questionable',
  notReliable = 'notReliable',
}

export interface MailingList {
  mailingListId: string
  shopperId: string
  name: string
  createdOn: number
  lastUpdatedOn: number
  addresses: Array<IAddress>
}
