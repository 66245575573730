import { UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import { AddressWithSuggestion, DuplicatedAddressGroup, IAddressDetails } from '../types/address-list.types'
import { AddressType } from '../types/address-multi-selection.types'

export function getIdForAddressDetail(address: IAddressDetails): string {
  return String(address.rowIndex)
}

export function getIdForInvalid(address: AddressWithSuggestion): string {
  return getIdForAddressDetail(address.originalAddress)
}

export function getIdForDuplicateGroup(address: DuplicatedAddressGroup): string {
  return address.addresses.map(getIdForAddressDetail).join('-')
}

export function getIdsForDuplicates(addressGroup: DuplicatedAddressGroup): string[] {
  return addressGroup.addresses.map(address => getIdForAddressDetail(address))
}

export function getValidAddress(addressRes: UploadedAddressesResponse, id: string): AddressWithSuggestion | null {
  const originalAddress = addressRes.validAddresses?.find(address => getIdForAddressDetail(address) === id)
  if (!originalAddress) return null
  return {
    originalAddress,
    suggestedAddress: undefined,
  }
}

export function getInvalidAddress(addressRes: UploadedAddressesResponse, id: string): AddressWithSuggestion | null {
  return addressRes.invalidAddresses?.find(address => getIdForInvalid(address) === id) || null
}

export function getDuplicateAddressGroup(addressRes: UploadedAddressesResponse, groupId: string): DuplicatedAddressGroup | null {
  return addressRes.duplicatedAddressGroups?.find(group => getIdForDuplicateGroup(group) === groupId) || null
}

export function getDuplicateAddress(addressRes: UploadedAddressesResponse, id: string): AddressWithSuggestion | null {
  const groups = addressRes.duplicatedAddressGroups || []
  for (let gi = 0; gi < groups.length; gi += 1) {
    const group = groups[gi]
    // const groupId = getIdForDuplicateGroup(group)
    for (let ai = 0; ai < group.addresses.length; ai += 1) {
      const address = group.addresses[ai]
      if (getIdForAddressDetail(address) === id) {
        return { originalAddress: address, suggestedAddress: undefined }
      }
    }
  }
  return null
}

export function getAddress(addressRes: UploadedAddressesResponse, id: string, type: AddressType): AddressWithSuggestion | null {
  switch (type) {
    case AddressType.VALID:
      return getValidAddress(addressRes, id)
    case AddressType.INVALID:
      return getInvalidAddress(addressRes, id)
    case AddressType.DUPLICATE:
      return getDuplicateAddress(addressRes, id)
    default:
      return null
  }
}
