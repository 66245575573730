import { useCallback, useState } from 'react'
import { getCurrentURLSearchParams, replaceQueryParamsWithoutRefresh } from 'utilities/navigate.utils'

function getInitialValue(key: string, fallback: string) {
  const params = getCurrentURLSearchParams()
  const currentValue = params.get(key)
  return currentValue || fallback
}

export function useQueryStringState(key: string, initialValue: string): [string, (newVal: string) => void] {
  const [value, setValue] = useState<string>(getInitialValue(key, initialValue))
  const onSetValue = useCallback(
    (newValue: string) => {
      setValue(newValue)
      replaceQueryParamsWithoutRefresh({ keysToSet: { [key]: newValue } })
    },
    [key],
  )

  return [value, onSetValue]
}
