import { Box, Callout, FlexBox, FluidImage, SelectionSet, SelectionSetInput, SelectionSetLabel, Typography } from '@vp/swan'
import { ErrorBoundary } from 'lib/errors'
import { FC } from 'react'
import classes from './strategy.module.scss'
import { useStrategySelectorOptions } from './use-strategy-options-msg.hook'

export const StrategySelectionSet: FC<{ strategy?: string; disabled: boolean; changeStrategy: (newStrategy: string | null) => void }> = ({
  strategy,
  disabled,
  changeStrategy,
}) => {
  const optionsToResolveIssues = useStrategySelectorOptions()
  return (
    <ErrorBoundary>
      <SelectionSet variant="single-select" selectedValue={strategy || ''} onSelectedValueChange={nextStrategy => changeStrategy(nextStrategy)}>
        {optionsToResolveIssues.map(option => (
          <SelectionSetInput value={option.value} key={option.value} disabled={disabled}>
            <SelectionSetLabel backgroundColor="standard" p={3}>
              <FlexBox alignItems="start">
                <FluidImage style={{ width: '24px' }} mt="2" src={option.icon} alt={option.altText} className={classes.icon} />
                <Box ml={4} className="flex-1-1">
                  <FlexBox justifyContent="space-between" alignItems="start">
                    <Typography fontSize={'standard'} fontWeight="bold">
                      {option.text}
                    </Typography>
                    {option.badgeText && (
                      <Callout size="mini" textAllCaps={false}>
                        {option.badgeText}
                      </Callout>
                    )}
                  </FlexBox>
                  <Typography fontSize={'small'} textColor="subtle" className={classes.subContent}>
                    {option.subText}
                  </Typography>
                </Box>
              </FlexBox>
            </SelectionSetLabel>
          </SelectionSetInput>
        ))}
      </SelectionSet>
    </ErrorBoundary>
  )
}
