import { ErrorBoundary } from 'lib/errors'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { AddressType } from 'modules/review/types/address-multi-selection.types'
import { getIdForAddressDetail } from 'modules/review/utilities/address.utils'
import { FC, useEffect, useMemo } from 'react'
import { AddressRow } from '../address-row/address-row.component'
import { AddressActionsProvider } from './address-actions.context'
import { AddressSelectionProvider, useAddressSelection } from './address-selection.context'

const ValidAddressTabContent: FC<{ addresses: IAddressDetails[] }> = ({ addresses }) => {
  const { currentPage, pageSize, selection, onReset } = useAddressSelection()
  const displayAddresses = useMemo(
    () => addresses?.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize),
    [addresses, currentPage, pageSize],
  )

  useEffect(() => {
    onReset(addresses.map(getIdForAddressDetail))
  }, [onReset, addresses])
  return (
    <>
      {displayAddresses.map((address, index, allAddresses) => {
        const key = getIdForAddressDetail(address)
        return (
          <AddressRow
            type={AddressType.VALID}
            key={key}
            rowId={key}
            isSelected={selection[key]}
            address={address}
            showDivider={index < allAddresses.length - 1}
          />
        )
      })}
    </>
  )
}

export const ValidAddressTab: FC<{ addresses: IAddressDetails[] }> = ({ addresses }) => (
  <ErrorBoundary>
    <AddressActionsProvider addressType={AddressType.VALID}>
      <AddressSelectionProvider addressType={AddressType.VALID} totalAddressCount={addresses.length}>
        <ValidAddressTabContent addresses={addresses} />
      </AddressSelectionProvider>
    </AddressActionsProvider>
  </ErrorBoundary>
)
