import { IAddressDetails } from 'modules/review/types/address-list.types'
import { AddressType } from 'modules/review/types/address-multi-selection.types'
import { getAddress } from 'modules/review/utilities/address.utils'
import { UploadedAddressesResponse } from 'types/upload-mailing-list.types'

export type EditableAddress = {
  original: IAddressDetails
  toEdit: IAddressDetails
  suggested?: IAddressDetails
}

export function getEditableAddresses(addRes: UploadedAddressesResponse | null | undefined, ids: string[], type: AddressType): EditableAddress[] {
  const editableAddresses: EditableAddress[] = []
  if (addRes) {
    ids.forEach(id => {
      let res = getAddress(addRes, id, type)
      if (!res && type !== AddressType.VALID) {
        res = getAddress(addRes, id, AddressType.VALID)
      }
      if (!res && type !== AddressType.INVALID) {
        res = getAddress(addRes, id, AddressType.INVALID)
      }
      if (!res && type !== AddressType.DUPLICATE) {
        res = getAddress(addRes, id, AddressType.DUPLICATE)
      }
      if (res) {
        editableAddresses.push({
          original: res.originalAddress,
          suggested: res.suggestedAddress,
          toEdit: { ...res.originalAddress },
        })
      }
    })
  }
  return editableAddresses
}
