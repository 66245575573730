import {
  Button,
  FlexBox,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
  Typography,
} from '@vp/swan'
import { useMsgAddressFormatAriaLabel, useMsgConfirmDeliverability, useMsgStandardize, useMsgZip } from 'lib/intl/msg-address-format-info.hooks'
import { useMsgAlternateTextValid } from 'lib/intl/msg-alternate-texts.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import validImg from '../../assets/valid.svg'

export const AddressFormatInfo: FC<unknown> = () => {
  const isPostcard = usePostcardPRDCheck()
  const alternateTextValid = useMsgAlternateTextValid()
  const [isOpen, setIsOpen] = useState(false)
  const modalCloseLabel = useMsgModalClose()
  const confirmDeliverability = useMsgConfirmDeliverability()
  const standardize = useMsgStandardize()
  const zip = useMsgZip()
  const addressFormatAriaLabel = useMsgAddressFormatAriaLabel()
  const addressFormatInfo = [
    {
      label: confirmDeliverability,
    },
    {
      label: standardize,
    },
    {
      label: zip,
    },
  ]

  const intl = useIntl()
  const message = isPostcard
    ? intl.formatMessage({
        defaultMessage:
          'To ensure everything is delivered safely, we standardized your address formats. Any changes you make to your mailing list will be saved.',
      })
    : intl.formatMessage({
        defaultMessage:
          'To ensure everything is delivered safely, we standardized your address formats. Any changes you make to your address list will be saved.',
      })

  const buttonMessage = intl.formatMessage({ defaultMessage: 'See address format information' })
  return (
    <>
      <Typography fontSize={'small'} textColor="subtle" mb={5}>
        {message}{' '}
        <Button skin="unstyled" style={{ textDecoration: 'underline', display: 'inline' }} onClick={() => setIsOpen(true)}>
          {buttonMessage}
        </Button>
      </Typography>
      <ModalDialog isOpen={isOpen} onRequestDismiss={() => setIsOpen(false)}>
        <ModalDialogContent aria-labelledby={addressFormatAriaLabel}>
          <ModalDialogNav>
            <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
          </ModalDialogNav>
          <ModalDialogHeader>
            <ModalDialogTitle>
              <FormattedMessage defaultMessage="Whenever you upload an address list, we review each recipient and:" />
            </ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>
            {addressFormatInfo.map(unit => (
              <FlexBox mb={4} alignItems="center" key={unit.label}>
                <img src={validImg} alt={alternateTextValid} />
                <Typography fontSize={'small'} ml={4} fontWeight="bold" as="span">
                  {unit.label}
                </Typography>
              </FlexBox>
            ))}
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}
