import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { Icon, ModalDialog, ModalDialogCloseButton, ModalDialogContent, ModalDialogHeader, ModalDialogNav, ModalDialogTitle, Typography } from '@vp/swan'
import { SectionLoaderWrapper } from 'components/loader/loader.component'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { useAddressList, useAddressListUpdate } from 'lib/address-list'
import { withErrorBoundary } from 'lib/errors'
import { useMsgAddressEditInformation } from 'lib/intl/msg-address-dialog.hooks'
import { useMsgEditRecipients } from 'lib/intl/msg-edit-action.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useToastSomethingWentWrong, useToastStandard } from 'lib/toast'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { RowAddressActionParams } from 'modules/review/types/address-multi-selection.types'
import { FC, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { UseMutateFunction } from 'react-query'
import { UpdateAddressRequest, UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import { EditableAddress, getEditableAddresses } from './edit-addresses.util'
import { PostEdit } from './post-edit.component'
import { SingleRowEdit } from './single-row-edit.component'

const EditAddressesInternal: FC<
  Omit<RowAddressActionParams, 'onExit'> & {
    update: UseMutateFunction<UploadedAddressesResponse, unknown, { request: UpdateAddressRequest }, unknown>
    inProgress: boolean
    hasError: boolean
    onExit: (ids: number[]) => void
    scrollRef: React.RefObject<HTMLInputElement>
  }
> = ({ type, ids, onExit, isOpen, update, inProgress, hasError, scrollRef }) => {
  const [currentIdx, setCurrentIdx] = useState(0)
  const [addressesToEdit, setAddressToEdit] = useState<EditableAddress[]>([])

  const { data: addresses } = useAddressList()
  const unknownErrorToast = useToastSomethingWentWrong()
  const standardToast = useToastStandard()
  const workId = useWorkIdQueryParam()
  const successMessage = useMsgEditRecipients(addressesToEdit.length)
  const { logError } = useLogger()

  const saveAddress = (address: IAddressDetails) => {
    const newAddresses = [...addressesToEdit]
    newAddresses[currentIdx] = {
      ...newAddresses[currentIdx],
      toEdit: address,
    }

    setAddressToEdit(newAddresses)

    if (currentIdx < addressesToEdit.length - 1) {
      setCurrentIdx(prev => prev + 1)
    } else {
      const updateAddressRequestBody: UpdateAddressRequest = {
        addressesToUpdate: newAddresses.map(u => u.toEdit),
        addressIndexesToRemove: [],
        duplicatedAddressIndexesToPromoteAsValid: [],
      }
      update(
        { request: updateAddressRequestBody },
        {
          onSuccess: () => {
            standardToast(successMessage)
            newRelicAddPageAction('Save edited addresses', { result: 'success' })

            onExit(updateAddressRequestBody.addressesToUpdate.map(u => u.rowIndex))
          },
          onError: () => {
            unknownErrorToast()
            newRelicAddPageAction('Save edited addresses', { result: 'error' })
            logError('Error occurred while editing addresses', { contextData: { workId, type } })
          },
        },
      )
    }
    scrollRef?.current?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
  }

  useEffect(() => {
    setAddressToEdit(getEditableAddresses(addresses, ids, type))
    setCurrentIdx(0)
  }, [addresses, ids, type])

  useEffect(() => {
    setCurrentIdx(0)
  }, [isOpen])

  return (
    <>
      {addressesToEdit.length ? (
        <ModalDialogHeader mb={5}>
          {addressesToEdit.length > 1 && (
            <Typography fontSize={'small'}>
              <FormattedMessage
                defaultMessage="{currentAddress} of {totalSelectedAddressesToEdit}"
                description="Shows current item number out of total items. e.g. 2 of 3"
                values={{ currentAddress: currentIdx + 1, totalSelectedAddressesToEdit: addressesToEdit.length }}
              />
            </Typography>
          )}
          <ModalDialogTitle fontWeight="bold">
            <FormattedMessage defaultMessage="Edit recipient" description="Title for the recipient address to be edited" />
          </ModalDialogTitle>
        </ModalDialogHeader>
      ) : null}
      {addressesToEdit.length ? (
        <SingleRowEdit
          editInProgress={inProgress}
          suggestion={addressesToEdit[currentIdx].suggested}
          address={addressesToEdit[currentIdx].toEdit}
          onUpdate={saveAddress}
          showError={hasError}
          isLastItem={currentIdx + 1 === addressesToEdit.length}
        />
      ) : null}
    </>
  )
}

const EditContainer: FC<RowAddressActionParams> = ({ ids, isOpen, onExit, type }) => {
  const {
    isEnabled,
    mutationResult: { mutate, isLoading, isError },
  } = useAddressListUpdate()
  const unknownErrorToast = useToastSomethingWentWrong()
  const [postEditIds, setPostEditRowIds] = useState<number[]>([])
  const addressEditInformation = useMsgAddressEditInformation()
  const modalCloseLabel = useMsgModalClose()
  const workId = useWorkIdQueryParam()
  const scrollRef = useRef<HTMLInputElement>(null)
  const { logError } = useLogger()

  const exitOnPostEdit = () => {
    onExit()
  }
  const onBack = () => {
    setPostEditRowIds([])
  }
  useEffect(() => {
    if (isError) {
      unknownErrorToast()
      logError('Error occurred while editing addresses', { contextData: { workId, type } })
    }
  }, [isError, unknownErrorToast, workId, type, logError])

  return (
    <SectionLoaderWrapper showLoader={isLoading || !isEnabled}>
      <div ref={scrollRef}>
        <ModalDialog variant="panel-right" bodyWidth="capped" isOpen={isOpen} onRequestDismiss={onExit} className="custom-panel-capped">
          <ModalDialogContent aria-labelledby={addressEditInformation} backgroundColor="standard" style={{ width: 450 }}>
            <ModalDialogNav>
              {postEditIds?.length ? <Icon skin="standard" iconType="arrowLeft" pr={2} style={{ cursor: 'pointer' }} onClick={onBack} /> : null}

              <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} onClick={onExit} />
            </ModalDialogNav>

            {!postEditIds?.length ? (
              <EditAddressesInternal
                ids={ids}
                isOpen={isOpen}
                onExit={setPostEditRowIds}
                type={type}
                update={mutate}
                inProgress={isLoading}
                hasError={isError}
                scrollRef={scrollRef}
              />
            ) : (
              <PostEdit ids={postEditIds} onExit={exitOnPostEdit} update={mutate} />
            )}
          </ModalDialogContent>
        </ModalDialog>
      </div>
    </SectionLoaderWrapper>
  )
}

export const EditAddresses = withErrorBoundary(EditContainer)
