import { useLogger } from '@vp/shared-capabilities-component-library/components'
import {
  Box,
  Button,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogTitle,
  SelectedValues,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
  Typography,
} from '@vp/swan'
import { FullPageLoader } from 'components/loader/loader.component'
import { useWorkIdQueryParam } from 'contexts/query-param.context'
import { useAddressList, useAddressListUpdate } from 'lib/address-list'
import { Error, withErrorBoundary } from 'lib/errors'
import { useMsgAddressCurrent, useMsgAddressResidents } from 'lib/intl/msg-address-dialog.hooks'
import { useMsgModalFooterError, useMsgModalKeepingDuplicates, useMsgModalMergeRecipients } from 'lib/intl/msg-modal-select-merge.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { useToastSomethingWentWrong, useToastStandard } from 'lib/toast'
import { DuplicatedAddressGroup, IAddressDetails } from 'modules/review/types/address-list.types'
import { RowAddressActionParams } from 'modules/review/types/address-multi-selection.types'
import { getDuplicateAddressGroup, getIdForAddressDetail } from 'modules/review/utilities/address.utils'
import { getAddressRequestForSelect } from 'modules/review/utilities/update-request.utils'
import { FC, useCallback, useMemo, useReducer } from 'react'
import { FormattedMessage } from 'react-intl'
import { UseMutateFunction } from 'react-query'
import { UpdateAddressRequest, UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import { noOp } from 'utilities/functions.utils'
import { AddressDisplayWithFallback } from '../address-display-with-fallback/address-display-with-fallback.component'

type MergeAddressesProps = Pick<RowAddressActionParams, 'onExit' | 'isOpen'> & {
  groupId: string
}
const currentResidentId = 'current-resident'

function useOnSelect(
  update: UseMutateFunction<UploadedAddressesResponse, unknown, { request: UpdateAddressRequest }, unknown>,
  onSuccess: () => void,
  selection: Record<string, boolean>,
  addressGroup?: DuplicatedAddressGroup | null,
) {
  const currentResidentFirstName = useMsgAddressCurrent()
  const currentResidentLastName = useMsgAddressResidents()
  const standardToastMessage = useMsgModalMergeRecipients(addressGroup?.addresses.length || 0)
  const standardToast = useToastStandard()
  const unknownErrorToast = useToastSomethingWentWrong()
  const workId = useWorkIdQueryParam()
  const { logError, logWarn } = useLogger()

  return useCallback(() => {
    const request = getAddressRequestForSelect(
      selection,
      {
        firstName: '',
        lastName: `${currentResidentFirstName} ${currentResidentLastName}`,
        currentResidentId,
      },
      addressGroup,
    )

    if (!request) {
      unknownErrorToast()
      logWarn('The request for saving same address duplicate selections is not valid', { contextData: { workId, request } })

      return
    }
    update(
      { request },
      {
        onSuccess: () => {
          standardToast(standardToastMessage)
          onSuccess()
        },
        onError: () => {
          unknownErrorToast()
          logError('Error occurred while saving same address duplicate selections', { contextData: { workId, request } })
        },
      },
    )
  }, [
    selection,
    currentResidentFirstName,
    currentResidentLastName,
    addressGroup,
    update,
    unknownErrorToast,
    standardToast,
    standardToastMessage,
    onSuccess,
    workId,
    logError,
    logWarn,
  ])
}

function selectionReducer(prevState: SelectedValues, action: SelectedValues) {
  if (!prevState[currentResidentId] && action[currentResidentId]) {
    return { [currentResidentId]: true } as SelectedValues
  }
  return {
    ...action,
    [currentResidentId]: false,
  } as SelectedValues
}

const initialState: SelectedValues = { [currentResidentId]: false }

const SelectAddressesInternal: FC<MergeAddressesProps> = ({ groupId, onExit, isOpen }) => {
  const { data: addresses } = useAddressList()
  const {
    isEnabled,
    mutationResult: { mutate: update, isLoading, isError: showError },
  } = useAddressListUpdate()
  const addressGroup = useMemo(() => {
    return addresses ? getDuplicateAddressGroup(addresses, groupId) : undefined
  }, [addresses, groupId])
  const [selection, setSelection] = useReducer(selectionReducer, initialState)

  const onSave = useOnSelect(update, onExit, selection, addressGroup)

  const modalCloseLabel = useMsgModalClose()
  const modalKeepingDuplicates = useMsgModalKeepingDuplicates()
  const modalFooterError = useMsgModalFooterError()

  const currentResidentFirstName = useMsgAddressCurrent()
  const currentResidentLastName = useMsgAddressResidents()
  const currentResidentAddress = useMemo(() => {
    if (!addressGroup?.addresses[0]) return null
    return {
      ...addressGroup.addresses[0],
      firstName: '',
      lastName: `${currentResidentFirstName} ${currentResidentLastName}`,
    } as IAddressDetails
  }, [addressGroup, currentResidentFirstName, currentResidentLastName])
  const selectedKeys = Object.keys(selection).filter(k => selection[k])
  return (
    <>
      <ModalDialog variant="panel-right" isOpen={isOpen} onRequestDismiss={noOp} className="custom-panel-capped">
        <ModalDialogContent aria-labelledby={modalKeepingDuplicates} backgroundColor="standard">
          <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} onClick={onExit} />
          <ModalDialogHeader>
            <ModalDialogTitle>
              <FormattedMessage defaultMessage="Which of these recipients would you like to keep?" />

              {addressGroup && (
                <Box style={{ position: 'relative' }}>
                  <Typography fontSize={'small'} fontWeight="bold" mt={3} style={{ position: 'absolute' }}>
                    <FormattedMessage defaultMessage="Select all recipients you would like to keep, we will delete the others." />
                  </Typography>
                </Box>
              )}
            </ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody mt={5}>
            <SelectionSet
              mt={6}
              variant="multi-select"
              selectedValues={selection}
              defaultSelectedValues={initialState}
              onSelectedValuesChange={val => setSelection(val)}
            >
              {addressGroup?.addresses.map(address => {
                const rowId = getIdForAddressDetail(address)
                return (
                  <SelectionSetInput value={rowId} key={rowId}>
                    <SelectionSetLabel>
                      <AddressDisplayWithFallback address={address} />
                    </SelectionSetLabel>
                  </SelectionSetInput>
                )
              })}
              <Typography fontSize={'small'} fontWeight="bold" mt={6} mb={4}>
                <FormattedMessage defaultMessage="Or you can send a single postcard to the current residents." />
              </Typography>
              {!!currentResidentAddress && (
                <SelectionSetInput value={currentResidentId} key={currentResidentId}>
                  <SelectionSetLabel>
                    <AddressDisplayWithFallback address={currentResidentAddress} />
                  </SelectionSetLabel>
                </SelectionSetInput>
              )}
            </SelectionSet>
          </ModalDialogBody>
          <ModalDialogFooter pinned>
            <Button width="full-width" skin="primary" onClick={onSave} disabled={!selectedKeys.length || isLoading || !isEnabled}>
              <FormattedMessage defaultMessage="Save" description="Button label for saving the selected address out of all the duplicate addresses" />
            </Button>
            {showError && <Error message={modalFooterError} textAlign="center" />}
          </ModalDialogFooter>
        </ModalDialogContent>
      </ModalDialog>
      {isLoading && <FullPageLoader />}
    </>
  )
}

export const SelectAddresses = withErrorBoundary(SelectAddressesInternal)
