import { ErrorBoundary } from 'lib/errors'
import { AddressWithSuggestion } from 'modules/review/types/address-list.types'
import { AddressType } from 'modules/review/types/address-multi-selection.types'
import { getIdForInvalid } from 'modules/review/utilities/address.utils'
import { FC, useEffect, useMemo } from 'react'
import { AddressRow } from '../address-row/address-row.component'
import { AddressActionsProvider } from './address-actions.context'
import { AddressSelectionProvider, useAddressSelection } from './address-selection.context'

export const InvalidAddressTabContent: FC<{ addresses: AddressWithSuggestion[] }> = ({ addresses }) => {
  const { currentPage, pageSize, selection, onReset } = useAddressSelection()
  const displayAddresses = useMemo(
    () => addresses?.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize),
    [addresses, currentPage, pageSize],
  )

  useEffect(() => {
    onReset(addresses.map(getIdForInvalid))
  }, [onReset, addresses])

  return (
    <>
      {displayAddresses.map((address, index, allAddresses) => {
        const key = getIdForInvalid(address)
        return (
          <AddressRow
            type={AddressType.INVALID}
            key={key}
            rowId={key}
            isSelected={selection[key]}
            address={address.originalAddress}
            errors={address.errors}
            validityConfidence={address.validityConfidence}
            showDivider={index < allAddresses.length - 1}
          />
        )
      })}
    </>
  )
}

export const InvalidAddressTab: FC<{ addresses: AddressWithSuggestion[] }> = ({ addresses }) => (
  <ErrorBoundary>
    <AddressActionsProvider addressType={AddressType.INVALID}>
      <AddressSelectionProvider addressType={AddressType.INVALID} totalAddressCount={addresses.length}>
        <InvalidAddressTabContent addresses={addresses} />
      </AddressSelectionProvider>
    </AddressActionsProvider>
  </ErrorBoundary>
)
