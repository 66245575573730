import { useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'

export function useMsgSelectAllValid() {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  return isPostcard
    ? intl.formatMessage({
        defaultMessage: 'Select all valid',
        description: 'Checkbox title to select all options e.g. Select all errors or Select all duplicates',
      })
    : intl.formatMessage({
        defaultMessage: 'Select all okay',
        description: 'Checkbox title to select all options e.g. Select all errors or Select all duplicates',
      })
}

export function useMsgSelectValid(count: number) {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  return isPostcard
    ? intl.formatMessage({ defaultMessage: '{count} valid selected', description: 'Shows total numbers of valid addresses selected' }, { count })
    : intl.formatMessage({ defaultMessage: '{count} okay selected', description: 'Shows total numbers of valid addresses selected' }, { count })
}

export function useMsgSelectAllInvalids() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Select all errors',
    description: 'Checkbox title to select all options e.g. Select all errors',
  })
}

export function useMsgSelectAllDuplicates() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Select all duplicates',
    description: 'Checkbox title to select all options e.g. Select all duplicates',
  })
}

export function useMsgSelectErrorAddresses(count: number) {
  const intl = useIntl()
  switch (count) {
    case 1:
      return intl.formatMessage({
        defaultMessage: '1 error selected',
        description: 'Shows 1 invalid addresses selected eg. 1 error selected',
      })
    case count:
      return intl.formatMessage(
        {
          defaultMessage: '{count} errors selected',
          description: 'Shows total numbers of invalid addresses selected eg. 5 errors selected',
        },
        { count },
      )
    default:
      return ''
  }
}

export function useMsgSelectDuplicateAddresses(count: number) {
  const intl = useIntl()
  switch (count) {
    case 1:
      return intl.formatMessage({
        defaultMessage: '1 duplicate selected',
        description: 'Shows 1 duplicate addresses selected eg. 1 duplicate selected',
      })
    case count:
      return intl.formatMessage(
        {
          defaultMessage: '{count} duplicates selected',
          description: 'Shows total numbers of duplicate addresses selected eg. 5 duplicates selected',
        },
        { count },
      )
    default:
      return ''
  }
}
