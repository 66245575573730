import { Typography } from '@vp/swan'
import { FullPageLoader } from 'components/loader/loader.component'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import { StrategySelectionSet } from './strategy-set.component'
import { useAutoFix } from './use-auto-fix.hook'
import { useStrategySelectorChangeHandler } from './use-strategy-change.hook'

export const Strategize: FC<{ onChange: () => void; areAllValid: boolean; isIdle: boolean }> = ({ onChange, isIdle, areAllValid }) => {
  const { isAutoFixInProgress, fix } = useAutoFix()
  const { inProgress, disabled, changeStrategy, strategy } = useStrategySelectorChangeHandler(fix, onChange)
  const isPostcard = usePostcardPRDCheck()
  const renderer = (
    <>
      <Typography aria-level={4} role="heading" fontSize={'large'} fontWeight="bold" mt={{ xs: 6, sm: 8 }} mb={5}>
        {isPostcard ? (
          <FormattedMessage defaultMessage="How should we handle your duplicates and errors?" />
        ) : (
          <FormattedMessage defaultMessage="How would you like to fix duplicates and errors?" />
        )}
      </Typography>
      <StrategySelectionSet strategy={strategy} changeStrategy={changeStrategy} disabled={disabled} />
    </>
  )
  if (isIdle) return renderer
  if (!isIdle && areAllValid && !strategy) return null
  return (
    <>
      {(inProgress || isAutoFixInProgress) && <FullPageLoader />}
      {renderer}
    </>
  )
}
