import { PageProps } from 'gatsby'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { AddressListProvider } from 'lib/address-list'
import { PagePattern } from 'lib/page-pattern'
import { ProductInfoProvider } from 'lib/product-info'
import { PageName } from 'lib/telemetry/tracking.types'
import { ReviewConfigContextProvider } from 'modules/review/contexts/review-config.context'
import { FC, memo } from 'react'
import { PageContextType } from 'types/application.types'
import { UploadedAddressesResponse } from 'types/upload-mailing-list.types'
import { ReviewPage } from './review.page'

const MemoizedPage = memo(ReviewPage)
const ReviewTemplate: FC<PageProps<unknown, PageContextType, { addresses: UploadedAddressesResponse }>> = ({ location, pageContext }) => {
  const trackingProductPageName = useTrackingProductPageName(PageName.REVIEW_PAGE)

  return (
    <PagePattern
      enforceSignIn
      enforceSignInForWork
      search={location.search}
      localeCulture={pageContext.localeCulture}
      i18nMessages={pageContext.messages}
      pageName={trackingProductPageName}
    >
      <ProductInfoProvider>
        <ReviewConfigContextProvider>
          <AddressListProvider>
            <MemoizedPage />
          </AddressListProvider>
        </ReviewConfigContextProvider>
      </ProductInfoProvider>
    </PagePattern>
  )
}

export default ReviewTemplate
