import { useIntl } from 'react-intl'

export function useMsgDeleteLabel() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Delete', description: 'Button label to delete an address' })
}

export function useMsgDeleteAriaLabel() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'delete the selected address', description: 'Label for deleting the selected address' })
}

export function useMsgDeleteAddresses(count: number) {
  const intl = useIntl()
  switch (count) {
    case 1:
      return intl.formatMessage({ defaultMessage: 'Delete recipient (1)', description: 'Title for delete 1 recipient action button e.g. Delete recipient(1)' })
    case count:
      return intl.formatMessage(
        {
          defaultMessage: 'Delete recipients({count})',
          description: 'Title for delete recipients action button e.g. Delete recipients(5)',
        },
        { count },
      )
    default:
      return ''
  }
}

export function useMsgDeleteRecipients(count: number) {
  const intl = useIntl()
  switch (count) {
    case 1:
      return intl.formatMessage({
        defaultMessage: '1 recipient deleted',
        description: '1 recipient to be deleted. e.g. 1 recipient deleted',
      })
    case count:
      return intl.formatMessage(
        {
          defaultMessage: '{count} recipients deleted',
          description: 'Total recipients to be deleted. e.g. 4 recipient deleted',
        },
        { count },
      )
    default:
      return ''
  }
}
