import { useMsgDeleteAddresses } from 'lib/intl/msg-delete-action.hooks'
import { useMsgEditDuplicateAddresses, useMsgEditErrorAddresses, useMsgEditValidAddress } from 'lib/intl/msg-edit-action.hooks'
import {
  useMsgSelectAllDuplicates,
  useMsgSelectAllInvalids,
  useMsgSelectAllValid,
  useMsgSelectDuplicateAddresses,
  useMsgSelectErrorAddresses,
  useMsgSelectValid,
} from 'lib/intl/msg-select-action.hooks'
import { useMsgUnSelectAllDuplicates, useMsgUnSelectAllInvalids, useMsgUnSelectAllValid } from 'lib/intl/msg-unselect-action.hooks'
import { AddressType } from '../../types/address-multi-selection.types'

export type MsgConfig = {
  checkboxTitleSelectAll: string
  checkboxTitleUnSelectAll: string
  addressSelection: string
  editActionButtonTitle: string
  deleteActionButtonTitle: string
}

function useHeaderMessagesForValid(count: number): MsgConfig {
  const selectAllValid = useMsgSelectAllValid()
  const selectValid = useMsgSelectValid(count)
  const unSelectAllValid = useMsgUnSelectAllValid()
  const deleteAddresses = useMsgDeleteAddresses(count)
  const editAddresses = useMsgEditValidAddress(count)

  return {
    checkboxTitleSelectAll: selectAllValid,
    checkboxTitleUnSelectAll: unSelectAllValid,
    addressSelection: selectValid,
    editActionButtonTitle: editAddresses,
    deleteActionButtonTitle: deleteAddresses,
  }
}

function useHeaderMessagesForInValid(count: number): MsgConfig {
  const selectAllInvalids = useMsgSelectAllInvalids()
  const selectInvalids = useMsgSelectErrorAddresses(count)
  const unSelectAllInvalids = useMsgUnSelectAllInvalids()
  const deleteAddresses = useMsgDeleteAddresses(count)
  const editAddresses = useMsgEditErrorAddresses(count)

  return {
    checkboxTitleSelectAll: selectAllInvalids,
    checkboxTitleUnSelectAll: unSelectAllInvalids,
    addressSelection: selectInvalids,
    editActionButtonTitle: editAddresses,
    deleteActionButtonTitle: deleteAddresses,
  }
}

function useHeaderMessagesForDuplicate(count: number): MsgConfig {
  const selectAllDuplicates = useMsgSelectAllDuplicates()
  const selectDuplicates = useMsgSelectDuplicateAddresses(count)
  const unSelectAllDuplicates = useMsgUnSelectAllDuplicates()
  const deleteAddresses = useMsgDeleteAddresses(count)
  const editAddresses = useMsgEditDuplicateAddresses(count)

  return {
    checkboxTitleSelectAll: selectAllDuplicates,
    checkboxTitleUnSelectAll: unSelectAllDuplicates,
    addressSelection: selectDuplicates,
    editActionButtonTitle: editAddresses,
    deleteActionButtonTitle: deleteAddresses,
  }
}

export function useAddressTabHeaderMessage(addressType: AddressType, count: number) {
  const validMsg = useHeaderMessagesForValid(count)
  const invalidMsg = useHeaderMessagesForInValid(count)
  const duplicateMsg = useHeaderMessagesForDuplicate(count)
  switch (addressType) {
    case AddressType.VALID:
      return validMsg
    case AddressType.INVALID:
      return invalidMsg
    case AddressType.DUPLICATE:
      return duplicateMsg
    default:
      return validMsg
  }
}
