import { useAddressList, useAddressListUpdate } from 'lib/address-list'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useToastSomethingWentWrong } from 'lib/toast'
import { getAddressAutoPromoteRequest } from 'modules/review/utilities/update-request.utils'
import { UploadedAddressesResponse } from 'types/upload-mailing-list.types'

export function usePromoteDuplicate(onSuccess: (newAddresses?: UploadedAddressesResponse) => void) {
  const { data: addresses } = useAddressList()
  const {
    isEnabled: isUpdateEnabled,
    mutationResult: { mutate: updateAddressList, isLoading: isPromoteDuplicateInProgress },
  } = useAddressListUpdate()

  const unknownErrorToast = useToastSomethingWentWrong()

  const promoteDuplicates = () => {
    if (!isUpdateEnabled) return

    const addressUpdateRequest = getAddressAutoPromoteRequest(addresses)
    if (!addressUpdateRequest) {
      onSuccess()
      return
    }

    updateAddressList(
      {
        request: addressUpdateRequest,
      },
      {
        onSuccess: newAddresses => {
          newRelicAddPageAction('Auto fix mailing list', { result: 'success' })
          onSuccess(newAddresses)
        },
        onError: () => {
          unknownErrorToast
          newRelicAddPageAction('Auto promote duplicates for mailing list', { result: 'error' })
        },
      },
    )
  }

  return { isPromoteDuplicateInProgress, promoteDuplicates }
}
