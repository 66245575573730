import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { FlexBox, H1, Typography } from '@vp/swan'
import { TabularShimmer } from 'components/loader/shimmer/shimmer.component'
import { useRequestIdQueryParam } from 'contexts/query-param.context'
import { Error } from 'lib/errors'
import { useMsgErrorWhileFetching } from 'lib/intl/msg-errors.hooks'
import { useMsgDuplicateAddress, useMsgInvalidAddress, useMsgValidAddress } from 'lib/intl/msg-validation-header.hooks'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import duplicateImg from '../../assets/duplicate.svg'
import invalidImg from '../../assets/invalid.svg'
import validImg from '../../assets/valid.svg'
import classes from './validation-header.module.scss'

type ExcelUploadProgressProps = {
  valid: number
  duplicate: number
  invalid: number
  isLoading: boolean
  isError: boolean
  isIdle: boolean
  isSuccess: boolean
}

type ExcelUploadProgressType = {
  label: string
  image: string
  count: number
}

export const ValidationHeader: FC<ExcelUploadProgressProps> = ({ valid, duplicate, invalid, isLoading, isError, isIdle, isSuccess }) => {
  const duplicateLabel = useMsgDuplicateAddress(duplicate)
  const invalidLabel = useMsgInvalidAddress(invalid)
  const isPostcard = usePostcardPRDCheck()
  const validLabel = useMsgValidAddress(valid)
  const errorWhileFetching = useMsgErrorWhileFetching()
  const requestId = useRequestIdQueryParam()
  const { logError } = useLogger()

  const uploadProgressData: ExcelUploadProgressType[] = [
    {
      label: validLabel,
      image: validImg,
      count: valid,
    },
    {
      label: duplicateLabel,
      image: duplicateImg,
      count: duplicate,
    },
    {
      label: invalidLabel,
      image: invalidImg,
      count: invalid,
    },
  ]

  useEffect(() => {
    if (isError) {
      logError('Error occurred while fetching addresses', { contextData: { requestId } })
    }
  }, [isError, requestId, logError])

  return (
    <section>
      {isError && <Error message={errorWhileFetching} textAlign="left" />}
      <H1 style={{ textAlign: 'left' }} fontSize={'x2large'}>
        {isPostcard ? (
          <FormattedMessage defaultMessage="Let's review your recipients." description="Heading to validate the recipients" />
        ) : (
          <FormattedMessage defaultMessage="Let's review your addresses." description="Heading to validate the addresses" />
        )}
      </H1>
      {(isIdle || isLoading) && <TabularShimmer className={classes.shimmerContainer} mt={5} rows={1} cols={3} />}
      {!isError && !isLoading && isSuccess && (
        <FlexBox alignItems="center" pt={5}>
          {uploadProgressData
            .filter(u => u.count)
            .map(unit => (
              <FlexBox key={unit.label}>
                <img src={unit.image} alt="" />
                <Typography fontSize={'small'} textAlign="left" mr={{ xs: 4, sm: 4, md: 5, lg: 6 }} ml={3}>
                  {unit.label}
                </Typography>
              </FlexBox>
            ))}
        </FlexBox>
      )}
    </section>
  )
}
