import { Pagination as SwanPagination, PaginationButton, PaginationEllipses, PaginationStep } from '@vp/swan'
import { ErrorBoundary } from 'lib/errors'
import { useMsgPaginationCurrentPage, useMsgPaginationLabel, useMsgPaginationNextButton, useMsgPaginationPreviousButton } from 'lib/intl/msg-pagination.hooks'
import { FC } from 'react'
import { DOTS, usePagination } from './pagination.hook'

type PageType = {
  onPageChange: (page: number) => void
  totalCount: number
  currentPage: number
  siblingCount?: number
  pageSize: number
}

export const Pagination: FC<PageType> = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) => {
  const paginationLabel = useMsgPaginationLabel()
  const paginationPreviousButton = useMsgPaginationPreviousButton()
  const paginationCurrentPage = useMsgPaginationCurrentPage(currentPage)
  const paginationNextButton = useMsgPaginationNextButton()
  const paginationRange = usePagination(totalCount, pageSize, currentPage, siblingCount)
  const lastPage = paginationRange && paginationRange[paginationRange.length - 1]
  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null
  }

  const onNext = () => {
    if (currentPage < lastPage) {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  return (
    <ErrorBoundary>
      <SwanPagination accessibleText={paginationLabel} style={{ justifyContent: 'center' }} p={3}>
        <PaginationButton variant="previous" accessibleText={paginationPreviousButton} disabled={currentPage === 1} onClick={onPrevious} />

        {paginationRange?.map(pageNumber => {
          if (pageNumber === DOTS) {
            return <PaginationEllipses key={pageNumber} />
          }

          return (
            <PaginationStep
              key={pageNumber}
              active={pageNumber === currentPage}
              accessibleText={paginationCurrentPage}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </PaginationStep>
          )
        })}
        <PaginationButton variant="next" accessibleText={paginationNextButton} onClick={onNext} disabled={currentPage === lastPage} />
      </SwanPagination>
    </ErrorBoundary>
  )
}
