import { useAddressList, useAddressListUpdate } from 'lib/address-list'
import { useMsgDeleteRecipients } from 'lib/intl/msg-delete-action.hooks'
import { newRelicAddPageAction } from 'lib/new-relic'
import { useToastSomethingWentWrong, useToastStandard } from 'lib/toast'
import { useAddressActions } from 'modules/review/components/address-tab/address-actions.context'
import { useReviewDeleteConfirmationConfig } from 'modules/review/contexts/review-config.context'
import { IAddressDetails } from 'modules/review/types/address-list.types'
import { AddressType } from 'modules/review/types/address-multi-selection.types'
import { getAddress } from 'modules/review/utilities/address.utils'
import { noOp, notEmpty } from 'utilities/functions.utils'

export function useOnDeleteHook(ids: string[], type: AddressType, onSuccess = noOp, checkForDeleteConfirmation = true) {
  const {
    isEnabled,
    mutationResult: { mutate: update, isLoading, isError },
  } = useAddressListUpdate()
  const { data: addresses } = useAddressList()
  const [showDeleteConfirmation] = useReviewDeleteConfirmationConfig()
  const { onDelete } = useAddressActions()
  const unknownErrorToast = useToastSomethingWentWrong()
  const toastMessage = useMsgDeleteRecipients(ids.length)
  const standardToast = useToastStandard()
  return {
    isEnabled,
    isLoading,
    isError,
    onDelete: () => {
      if (checkForDeleteConfirmation && showDeleteConfirmation) {
        onDelete(ids)
        return
      }
      if (addresses) {
        const updateAddressRequestBody = {
          addressesToUpdate: [] as IAddressDetails[],
          addressIndexesToRemove: ids
            .map(id => {
              const addr = getAddress(addresses, id, type)
              return addr?.originalAddress.rowIndex
            })
            .filter(notEmpty),
          duplicatedAddressIndexesToPromoteAsValid: [] as number[],
        }
        update(
          { request: updateAddressRequestBody },
          {
            onSuccess: () => {
              standardToast(toastMessage)
              onSuccess()
              newRelicAddPageAction('Delete addresses', { result: 'success' })
            },
            onError: () => {
              unknownErrorToast()
              newRelicAddPageAction('Delete addresses', { result: 'error' })
            },
          },
        )
      } else {
        unknownErrorToast()
      }
    },
  }
}
