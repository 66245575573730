import { useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'

export function useMsgEditLabel() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Edit', description: 'Button label to edit an address' })
}

export function useMsgEditValidAddress(count: number) {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  return intl.formatMessage(
    {
      defaultMessage: 'Edit selected okay({count})',
      description: 'Label for taking edit action on selected valid addresses with address count e.g. Edit selected valid(6)',
    },
    { count, validLabel: isPostcard ? 'valid' : 'okay' },
  )
}

export function useMsgEditErrorAddresses(count: number) {
  const intl = useIntl()
  switch (count) {
    case 1:
      return intl.formatMessage({
        defaultMessage: 'Edit selected error (1)',
        description: 'Label for taking edit action on selected invalid addresses with address count 1 e.g. Edit selected error(1)',
      })
    case count:
      return intl.formatMessage(
        {
          defaultMessage: 'Edit selected errors({count})',
          description: 'Label for taking edit action on selected invalid addresses with address count more than 1 e.g. Edit selected errors(6)',
        },
        { count },
      )
    default:
      return ''
  }
}

export function useMsgEditDuplicateAddresses(count: number) {
  const intl = useIntl()
  switch (count) {
    case 1:
      return intl.formatMessage({
        defaultMessage: 'Edit selected duplicate (1)',
        description: 'Label for taking edit action on selected duplicate addresses with address count 1 e.g. Edit duplicate error(1)',
      })
    case count:
      return intl.formatMessage(
        {
          defaultMessage: 'Edit selected duplicates({count})',
          description: 'Label for taking edit action on selected duplicate addresses with address count more than 1 e.g. Edit selected errors(6)',
        },
        { count },
      )
    default:
      return ''
  }
}

export function useMsgEditRecipients(count: number) {
  const intl = useIntl()
  switch (count) {
    case 1:
      return intl.formatMessage({
        defaultMessage: '1 recipient edited',
        description: '1 recipient to be edited. e.g. 1 recipient edited',
      })
    case count:
      return intl.formatMessage(
        {
          defaultMessage: '{count} recipients edited',
          description: 'Total recipients to be edited. e.g. 4 recipient edited',
        },
        { count },
      )
    default:
      return ''
  }
}
