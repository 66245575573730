import { useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'

export function useMsgDuplicateAddress(count: number) {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  switch (count) {
    case 1:
      return isPostcard
        ? intl.formatMessage({ defaultMessage: '1 duplicated', description: 'Shows if 1 address is present, e.g. 1 duplicate' })
        : intl.formatMessage({ defaultMessage: '1 Duplicated', description: 'Shows if 1 address is present, e.g. 1 duplicate' })
    case count:
      return isPostcard
        ? intl.formatMessage({ defaultMessage: '{count} duplicates', description: 'Shows total number of duplicate addresses, e.g. 3 duplicates' }, { count })
        : intl.formatMessage({ defaultMessage: '{count} Duplicates', description: 'Shows total number of duplicate addresses, e.g. 3 duplicates' }, { count })
    default:
      return ''
  }
}

export function useMsgInvalidAddress(count: number) {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  switch (count) {
    case 1:
      return isPostcard
        ? intl.formatMessage({ defaultMessage: '1 error', description: 'Shows if 1 address is present, e.g. 1 error' })
        : intl.formatMessage({ defaultMessage: '1 Error', description: 'Shows if 1 address is present, e.g. 1 error' })
    case count:
      return isPostcard
        ? intl.formatMessage({ defaultMessage: '{count} errors', description: 'Shows total number of invalid addresses, e.g. 3 errors' }, { count })
        : intl.formatMessage({ defaultMessage: '{count} Errors', description: 'Shows total number of invalid addresses, e.g. 3 errors' }, { count })
    default:
      return ''
  }
}

export function useMsgValidAddress(count: number) {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  return isPostcard
    ? intl.formatMessage({ defaultMessage: '{count} valid', description: 'Shows total number of valid addresses. E.g. 3 valid' }, { count })
    : intl.formatMessage({ defaultMessage: '{count} Okay', description: 'Shows total number of Okay addresses. E.g. 3 Okay' }, { count })
}
