import { useIntl } from 'react-intl'

export function useMsgPaginationLabel() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Pagination', description: 'Visually hidden text for Pagination label' })
}

export function useMsgPaginationPreviousButton() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Previous Page', description: 'Visually hidden text for previous page icon' })
}

export function useMsgPaginationCurrentPage(count: number) {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Page {count}', description: 'Visually hidden text for current page number. e.g. Page 4' }, { count })
}

export function useMsgPaginationNextButton() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Next Page', description: 'Visually hidden text for next page icon' })
}
