import { useIntl } from 'react-intl'

export function useMsgModalMergeRecipients(count: number) {
  const intl = useIntl()
  return intl.formatMessage(
    {
      defaultMessage: 'Merged {count} recipients',
      description: 'Toast message for total recipients that are merged. e.g. Merged 2 recipients',
    },
    { count },
  )
}

export function useMsgModalKeepingDuplicates() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Keeping duplicate addresses',
    description: 'For handling duplicates with the same address',
  })
}

export function useMsgModalFooterError() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'Some error occurred. Please try again.' })
}
